import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'

import FadeWrapper from '../components/fadeWrapper'
import SEO from '../components/seo'
import Cover from '../styles/cover'

const FAQWrapper = styled.div`
  h2 {
    font-size: 1.5rem;
    text-align: left;

    &::after {
      display: none;
    }
  }
`

const FAQ = ({
  data: {
    markdownRemark: {
      html,
      frontmatter: { title },
    },
  },
}) => (
  <FadeWrapper>
    <SEO title='FAQ' />
    <Cover width='60vw' margin='1.875rem auto 0 auto'>
      <FAQWrapper>
        <div>
          <h1>{title}</h1>
          <div
            className='flow'
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{ __html: html }}
          />
        </div>
      </FAQWrapper>
    </Cover>
  </FadeWrapper>
)

export const faqPageQuery = graphql`
  query faqquery {
    markdownRemark(fileAbsolutePath: { glob: "**/src/cms/faq/faq.md" }) {
      frontmatter {
        title
      }
      html
    }
  }
`

export default FAQ
